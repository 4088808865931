import Cookies from "universal-cookie";

const cookies = new Cookies();

export const initialState = {
  networkStatus: false,
  userLoggedIn: Boolean(cookies.get("trekcard_token")),
  userData: null,
  credentials: {
    data: {},
    token: {
      trekcard_token: cookies.get("trekcard_token"),
      trekcard_user: cookies.get("trekcard_user"),
      trekcard_location: cookies.get("trekcard_location"),
      trekcard_location_id: cookies.get("trekcard_location_id"),
      trekcard_role: localStorage.getItem("trekcard_role"),
      trekcard_permissions: cookies.get("trekcard_permissions"),
    },
    // trekcard_user: cookies.get("trekcard_user"),
    // trekcard_user: cookies.get("trekcard_user"),
    // trekcard_user: cookies.get("trekcard_user"),
    // trekcard_entrypoint_user: cookies.get("trekcard_entrypoint_user"),
  },
  adminDashboardData: {
    userLoggedIn: Boolean(cookies.get("adminToken")),
    adminToken: cookies.get("adminToken"),
  },
  entryPointToken: cookies.get("entryPointToken"),
  checkpoints: null,
  trailData: null,
  checkPointToken: cookies.get("checkPointToken"),
  currentCheckPoint: {
    data: null,
    id: null,
    name: localStorage.getItem("checkPointName"),
  },
  // userName: null
  userName: {
    name: cookies.get("userName"),
  },
  logoutCount: 0,
  showSidebar: false,
};

export default function ourReducer(draft, action) {
  switch (action.type) {
    case "setNetworkStatus":
      draft.networkStatus = action.value;
      return;
    case "setToken":
      draft.credentials.token.trekcard_token = action.trekcard_token;
      draft.credentials.token.trekcard_user = action.trekcard_user;
      return;
    // case "setUserData":
    //   draft.userData = action.value;
    //   return;
    case "adminLogin":
      draft.userLoggedIn = true;
      draft.credentials.data = action.value;
      draft.credentials.token.trekcard_token = action.value.token;
      draft.credentials.token.trekcard_user = action.value.username;
      draft.credentials.token.trekcard_location = "admin";
      draft.credentials.token.trekcard_role = action.value?.roles[0].name;
      draft.credentials.token.trekcard_permissions =
        action.value?.roles[0].permissions.map((item) => item.id);
      console.log(
        "'permission",
        action.value?.roles[0].permissions.map((item) => item.id)
      );
      return;
    case "checkPointLogin":
      draft.userLoggedIn = true;
      draft.credentials.data = action.value;
      draft.credentials.token.trekcard_token = action.value.token;
      draft.credentials.token.trekcard_user = action.value.username;
      draft.credentials.token.trekcard_location = "checkpoint";
      draft.credentials.token.trekcard_location_id = action.id;
      return;
    case "entryPointLogin":
      draft.userLoggedIn = true;
      draft.credentials.data = action.value;
      draft.credentials.token.trekcard_token = action.value.token;
      draft.credentials.token.trekcard_user = action.value.username;
      draft.credentials.token.trekcard_location = "entrypoint";
      return;
    case "userLogout":
      draft.logoutCount++;
      draft.userLoggedIn = false;
      window.location.reload();
      // draft.credentials.token.trekcard_token = null;
      // draft.credentials.token.trekcard_user = null;
      // draft.credentials.token.trekcard_location = null;
      return;
    case "setCheckPoints":
      draft.checkpoints = action.value;
      return;
    case "setTrailData":
      draft.trailData = action.value;
      return;
    case "setCurrentCheckpoint":
      draft.currentCheckPoint.id = action.id;
      draft.currentCheckPoint.name = action.name;
      draft.currentCheckPoint.data = action.value;
      return;
    case "setShowSidebar":
      draft.showSidebar = !draft.showSidebar;
      return;
    default:
      return;
  }
}
