import React, { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { motion } from "framer-motion";

import admin from "../assets/image/admin .svg";
import checkpoint from "../assets/image/checkpoint.svg";
import entrypoint from "../assets/image/entry.svg";
import khumbuIcon from "../assets/image/khumbu.png";
import namche from "../assets/image/hero-image.jpg";
// CONTEXT
import StateContext from "../context/StateContext";

const cookies = new Cookies();

const LandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const appState = useContext(StateContext);
  const token = appState.credentials.token.trekcard_token;

  const loginList = [
    { name: "Entry Point", link: "/entrypoint", image: entrypoint },
    { name: "Check Point", link: "/checkpoint", image: checkpoint },
    { name: "Admin Dashboard", link: "/admin", image: admin },
  ];

  useEffect(() => {
    if (!token && location.pathname !== "/cpanel") {
      navigate("/");
    } else if (location.pathname === "/cpanel") {
      navigate("/cpanel");
    } else {
      if (appState.credentials.token.trekcard_location === "checkpoint") {
        navigate(
          "/checkpoint/dashboard/" + cookies.get("trekcard_location_id")
        );
      }
      if (appState.credentials.token.trekcard_location === "admin") {
        navigate("/admin/dashboard");
      }
      if (appState.credentials.token.trekcard_location === "entrypoint") {
        navigate("/entrypoint/dashboard");
      }
    }
  }, [token]);

  return (
    <main
      className="checkpost-home"
      style={{
        backgroundImage: `linear-gradient(0deg, #00000088 30%, #ffffff44 100%),url(${namche})`,
      }}
    >
      <div className="content">
        <div className="container">
          <div className="login-body">
            <div className="text-content">
              <motion.h1
                className="new"
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                exit={{ y: 100 }}
                transition={{ duration: 0.5 }}
              >
                PRIVILAGE CARD
              </motion.h1>
              <motion.p
                className="medium-text"
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                exit={{ y: 100 }}
                transition={{ duration: 0.7 }}
              >
                Rather fear not trying
              </motion.p>
            </div>
            <div className="form-content new">
              <motion.div
                className="text-content"
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                exit={{ y: 100 }}
                transition={{ duration: 0.5 }}
              >
                <div className="logo">
                  <img src={khumbuIcon} alt="" />
                </div>
                <div className="text-container">
                  <p className="medium-bold-text">Company Name</p>
                </div>
              </motion.div>
              <div className="menu-container new">
                {loginList.map((item, i) => (
                  <motion.div
                    className="login-content"
                    key={i}
                    initial={{ y: 100 }}
                    animate={{ y: 0 }}
                    exit={{ y: 100 }}
                    transition={
                      (i === 0 && { duration: 0.7 }) ||
                      (i === 1 && { duration: 0.9 }) ||
                      (i === 2 && { duration: 1.1 })
                    }
                  >
                    <Link to={item.link}>
                      <div className="logo-container">
                        <img src={item.image} alt="" />
                      </div>
                      <div className="text-container">
                        <p>{item.name}</p>
                      </div>
                    </Link>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-text">
          <p>Powered by: Affix Link Pvt. Ltd.</p>
        </div>
      </div>
    </main>
  );
};

export default LandingPage;
