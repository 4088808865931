export const initialState = {
  email: {
    value: "",
    message: "",
    error: false,
  },
  password: {
    value: "",
    message: "",
    error: false,
  },
  checkPoint: {
    value: "",
    name: "",
    message: "",
    error: false,
  },
  type: {
    value: false,
    message: "",
  },
  error: false,
  submitCount: 0,
  isPending: false,
};

export default function ourReducer(draft, action) {
  switch (action.type) {
    case "emailChange":
      draft.email.value = action.value;
      draft.email.error = false;
      draft.error = false;
      break;
    case "emailError":
      draft.email.message = "You must provide a valid email address";
      draft.email.error = true;
      draft.error = true;
      break;
    case "passwordChange":
      draft.password.value = action.value;
      draft.password.error = false;
      draft.error = false;
      break;
    case "checkPointChange":
      const { checkpoints } = action;
      draft.checkPoint.value = action.value;
      checkpoints.forEach((item) => {
        if (action.value == item.id) {
          draft.checkPoint.name = item.name;
        }
      });
      draft.checkPoint.error = action.value;
      draft.error = false;
      break;
    case "submitForm":
      if (!draft.email.hasErrors && !draft.password.hasErrors) {
        draft.submitCount++;
      } else {
        draft.error = true;
      }
      break;
    case "errorOccur":
      draft.error = true;
      break;
    case "setPending":
      draft.isPending = action.value;
      break;
    case "resetSumbitCount":
      draft.submitCount = 0;
      break;
    case "setType":
      draft.type.value = !draft.type.value;
      break;
    default:
      return;
  }
}
