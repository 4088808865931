import React, { useEffect, useContext, memo } from "react";
import { useImmerReducer } from "use-immer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { motion } from "framer-motion";

// ASSETS
import heroImage from "../../assets/image/hero-image.jpg";
import khumbuIcon from "../../assets/image/khumbu.png";
import StateContext from "../../context/StateContext";

import { EyeCloseIcon, EyeOpenIcon } from "../../handler/icons";
import DispatchContext from "../../context/DispatchContext";
import ourReducer, { initialState } from "../../Reducer/entrypoint/login";

const cookies = new Cookies();

const EntryPointLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const appState = useContext(StateContext);
  const token = appState.credentials.token.trekcard_token;

  useEffect(() => {
    if (!token && location.pathname !== "/cpanel") {
      navigate("/entrypoint");
    } else if (location.pathname === "/cpanel") {
      navigate("/cpanel");
    } else {
      if (appState.credentials.token.trekcard_location == "checkpoint") {
        navigate(
          "/checkpoint/dashboard/" + cookies.get("trekcard_location_id")
        );
      }
      if (appState.credentials.token.trekcard_location == "admin") {
        navigate("/admin/dashboard");
      }
      if (appState.credentials.token.trekcard_location == "entrypoint") {
        navigate("/entrypoint/dashboard");
      }
    }
  }, [token]);

  return (
    <main
      className="checkpost-home admin-login"
      style={{
        backgroundImage: `linear-gradient(0deg, #00000088 30%, #ffffff44 100%),url(${heroImage})`,
      }}
    >
      <div className="container">
        <div className="login-body">
          <div className="text-content">
            <motion.h1
              initial={{ y: 100 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.5 }}
            >
              PRIVILAGE CARD
            </motion.h1>
            <motion.p
              className="medium-text"
              initial={{ y: 100 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.7 }}
            >
              Rather fear not trying
            </motion.p>
          </div>
          <div className="form-content" style={{ maxWidth: "400px" }}>
            <motion.div
              className="text-content mb-5"
              initial={{ y: 100 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.5 }}
            >
              <div className="logo">
                <img
                  src={khumbuIcon}
                  alt=""
                  onClick={() => navigate("/")}
                  className="rounded-circle zoom-in"
                />
              </div>
              <div className="text-container">
                <p className="medium-bold-text">Company Name</p>
              </div>
            </motion.div>
            <LoginForm />
            <motion.div
              className="my-2 text-end"
              initial={{ y: 100 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.9 }}
            >
              <Link to="/forgot-password" className="text-hover">
                Forgot password?
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
      <p
        style={{
          fontWeight: "500",
          position: "fixed",
          bottom: "15px",
          right: "35px",
        }}
      >
        Powered by: Affix Link Pvt. Ltd.
      </p>
    </main>
  );
};

const LoginForm = memo(function LoginForm() {
  const navigate = useNavigate();
  const appDispatch = useContext(DispatchContext);
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (state.submitCount > 0) {
      dispatch({ type: "setPending", value: true });
      const ourRequest = axios.CancelToken.source();
      async function postData() {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/login`,
            {
              email: state.email.value,
              password: state.password.value,
            },
            { cancelToken: ourRequest.token }
          );
          if (response.data) {
            if (response.data.data.roles[0].id === 3) {
              appDispatch({
                type: "entryPointLogin",
                value: response.data.data,
              });
              navigate("/entrypoint/dashboard");
            } else {
              toast.error("Unauthorized");
            }
          }
          dispatch({ type: "setPending", value: false });
        } catch (error) {
          console.log(error);
          dispatch({ type: "errorOccur" });
          if (error.code === "ERR_NETWORK") {
            toast.error("Network Error");
          } else if (error.response?.data?.data?.error) {
            toast.error(error.response.data.data.error);
          }
          dispatch({ type: "setPending", value: false });
        }
      }
      postData();
    }
  }, [state.submitCount]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!/^\S+@\S+$/.test(state.email.value)) {
      dispatch({ type: "emailError" });
    } else {
      dispatch({ type: "submitForm" });
    }
  };
  return (
    <motion.form
      className="form-container"
      onSubmit={handleFormSubmit}
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      exit={{ y: 100 }}
      transition={{ duration: 0.7 }}
    >
      <div className="form-header">
        <h2 className="fw-bold">Entry Point Login</h2>
      </div>
      <div className="form-body">
        <div className="form-input">
          <label htmlFor="username">Email</label>
          <input
            type="text"
            id="username"
            className="px-2 py-3"
            autoFocus
            placeholder="Type your email"
            onChange={(e) =>
              dispatch({ type: "emailChange", value: e.target.value })
            }
          />
        </div>
        {state.email.error && (
          <p className="error-message">{state.email.message}</p>
        )}
        <div className="form-input">
          <label htmlFor="password">Password</label>
          <div className="password-input">
            <input
              type={state.type.value ? "text" : "password"}
              id="password"
              className="px-2 py-3"
              placeholder="Type your password"
              onChange={(e) =>
                dispatch({
                  type: "passwordChange",
                  value: e.target.value,
                })
              }
            />
            <span onClick={() => dispatch({ type: "setType" })}>
              {state.type.value ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </span>
          </div>
        </div>
      </div>
      <div className="button-container">
        <button className="btn-primary zoom-in h-25">
          {state.isPending ? "Please wait ..." : "LOGIN"}
        </button>
      </div>
    </motion.form>
  );
});

export default EntryPointLogin;
